import axios from "axios";
const ApiUsers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/users`,
 
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
});

const ApiVehicles = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/vehicleStats`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
});

const ApiEcus = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/ecus`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
});

const ApiFiles= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/files`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
});

const ApiTransactions= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/transactions`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
});

const ApiOrders = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/orders`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiTickets = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/tickets`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiNotifications = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/notifications`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiDashboard = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/dashboard`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiCredits = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/credits`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiCouponCodes = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/coupon_codes`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiDtc = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/dtc`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});
const ApiUpload = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/upload`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
   
});

const ApiEcuNumbers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/ecuNumbers`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiEcuNotes = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/ecu_notes`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiWarningMessages = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/warning_messages`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
   
});


const ApiPopup = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/popup`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
   
});

const ApiMails = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/mail`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
   
});

const ApiFilesToDownload = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/files_to_download`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // },
   
});

const ApiDfCodes = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/DfCodes`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});


const ApiDownload = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/download`,
  responseType: 'blob',
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiSettings = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/settings`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiSolutions = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/solutionOption`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

const ApiSolutionCategorys = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/solutionCategory`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});
const ApiSolutionsFormula = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/solutionOptionFormula`,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  //   "authorization" : localStorage.getItem('authorization')
  // }, 
});

export  {ApiEcus,ApiSolutionCategorys,ApiSolutionsFormula,ApiSolutions,ApiUsers,ApiVehicles,ApiFiles,ApiUpload,ApiTransactions,ApiOrders,ApiTickets,ApiNotifications,ApiDownload,ApiDashboard,ApiCredits,ApiDtc,ApiDfCodes,ApiWarningMessages,ApiEcuNumbers,ApiSettings,ApiCouponCodes,ApiEcuNotes,ApiPopup,ApiMails,ApiFilesToDownload};
