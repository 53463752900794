// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const DASHBOARD_ADMIN = '/dashboard';
export const DASHBOARD_TECH_STATS = '/dashboard_tech_stats';
export const DASHBOARD_CLIENT_STATS = '/dashboard_client_stats';

export const PROFILE = '/profile';

export const ALL_TRANSACTIONS = '/allTransactions';
export const MY_TRANSACTIONS = '/myTransactions';

export const MY_ORDERS = '/myOrders';
export const ALL_ORDERS = '/allOrders';
export const ORDER = '/order/:id';
export const CHECKOUT = '/checkout';

export const MY_TICKETS = '/myTickets';
export const ALL_TICKETS = '/allTickets';
export const TICKET_ADMIN = '/ticketAdmin/:id';
export const TICKET_CLIENT = '/ticket/client/:id';


export const MY_FILES = '/myFiles';
export const ALL_FILES = '/allFiles';
export const FILE = '/file/:id';

export const STAFF_MEMBERS = '/staff-members';
export const CUSTOMERS = '/customers';
export const EDIT_USER = '/editUser/:id'

export const ALL_VEHICLES = '/vehicleStats'
export const VEHICLE = '/vehicle/:id'
export const VEHICLES_WAITING = '/vehiclesWaiting'

export const EDIT_ECU = '/edit_ecu/:id'

export const TUNING = '/tuningStats'

export const SOLUTION_CATEGORY = '/solution_category/:id'
export const SOLUTION_COMBINATION = '/solution_combination/:id'

export const TUNING_FRAME = '/tuning_frame'
export const TUNING_FRAME_TPREMAPPING = '/tuning_frame_tpremapping'
export const TUNING_FRAME_TUNEMYRIDE = '/tuning_frame_tunemyride/'

export const PRICE_LIST_FRAME = '/price_list_frame'
export const PRICE_LIST = '/price_list'


export const NOTIFICATIONS  = '/notifications'

export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const FORGETPASSWORD = '/forgetPassword';
export const CHANGE_PASSWORD = '/change_password';
export const RESET_PASSWORD = '/reset_password/:token';

export const LOGOUT = '/logout';

export const SETTINGS_ADMIN = '/settingsAdmin';
export const SETTINGS = '/settings';

export const CREDITS = '/credits';
export const SINGLE_CREDIT = '/creditPack/:id';


export const SITE_SETTINGS = '/site-settings';

export const TRAINING = '/training';
export const HELP = '/help';
export const OBD_LOCATION = '/obd_location';
export const ECU_LOCATION = '/ecu_location';
export const DF_TO_PCODE = '/df_to_pcode';
export const DTC_CODES_MANUAL = '/dtc_codes_manual';
export const DTC_CODES = '/dtc_codes';
export const PINOUT = '/pinout';
export const TUTORIALS = '/tutorials'
export const DIAGRAM_WIRING = '/diagram_wiring';
export const ECU_NUMBERS = '/ecu_numbers';
export const ECU_NUMBERS_MANUAL = '/ecu_numbers_manual';
export const DOWNLOAD = '/download';
export const FILE_SECTION = '/tutorials/files'
export const ORDER_SECTION = '/tutorials/orders'
export const TICKET_SECTION = '/tutorials/tickets'
export const OTHERS_SECTION = '/tutorials/others'
export const MAILING = '/mailing';
export const POPUP_EDIT = '/popup_edit/:id';
export const POPUP_ADD = '/popup_add';
export const EDIT_MAIL = '/edit_mail/:id';
export const CREATE_MAIL = '/create_mail';

export const MESSENGER = '/messenger';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
